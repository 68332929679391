<template>
  <div class="container">
    <div class="mytool-wrapper">
      <keep-alive>
      <router-view v-if="$route.meta.isAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.isAlive" />
    </div>
    <DragBall @click="toHome" v-show="showDragBall">
      <div slot="value">首页</div>
    </DragBall>
  </div>
</template>
  
<script>
import DragBall from '../components/dragball'


export default {
  name: "layout",
  data() {
    return {
      isShow: true,
    };
  },

  components: {
    DragBall,
  },
  computed: {

    //计算是否显示返回首页
    showDragBall() {
      if (this.$route.path == "/") {
        return false;
      }
      else if (this.$route.path.indexOf("dealer") > -1) {
        //品牌列表模块
        return false;
      }
      else {
        return true;
      }
    },
  },
  created() {


  },
  methods: {
    toHome() {
      this.$router.replace({ path: "/" });
    },

  },
};
</script>
  
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;

  .mytool-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
</style>
  